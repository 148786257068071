import * as Yup from 'yup'
import _ from 'lodash'
import i18next from 'i18next'

const optionalString = () =>
  Yup.string('hello')
    .nullable()

const requiredBool = () =>
  Yup.bool()
    .nullable()
    .required('FORM_VALIDATION_REQUIRED')
    .oneOf([true], 'FORM_VALIDATION_REQUIRED')

const requiredString = () =>
  Yup.string('hello')
    .nullable()
    .trim()
    .required('FORM_VALIDATION_REQUIRED')

const requiredStringWhen = (field, test) =>
  Yup.string().when(field, {
    is: (val) => val === test,
    then: requiredString()
  })

const requiredProfessionWhen = (field, test, professions) =>
  Yup.string().when(field, {
    is: (val) => val === test && professions.length > 1,
    then: requiredString()
  })

const email = () => requiredString().email('FORM_VALIDATION_EMAIL')

const optionalPassword = () => Yup.string().min(9, 'FORM_VALIDATION_PASSWORD_LENGTH')
  .matches(/([A-Z])/g, 'FORM_VALIDATION_PASSWORD_UPPERCASE')
  .matches(/([a-z])/g, 'FORM_VALIDATION_PASSWORD_LOWERCASE')
  .matches(/([\d])/g, 'FORM_VALIDATION_PASSWORD_NUMERIC')

const password = () => requiredString().min(9, 'FORM_VALIDATION_PASSWORD_LENGTH')
  .matches(/([A-Z])/g, 'FORM_VALIDATION_PASSWORD_UPPERCASE')
  .matches(/([a-z])/g, 'FORM_VALIDATION_PASSWORD_LOWERCASE')
  .matches(/([\d])/g, 'FORM_VALIDATION_PASSWORD_NUMERIC')

const optionalConfirmPassword = (passwordFieldName) =>
  Yup.string().when(passwordFieldName, {
    is: (val) => !!val,
    then: requiredString().oneOf(
      [Yup.ref(passwordFieldName), null],
      'FORM_VALIDATION_CONFIRM_PASSWORD'
    )
  })

const confirmPassword = (passwordFieldName) =>
  requiredString().oneOf(
    [Yup.ref(passwordFieldName), null],
    'FORM_VALIDATION_CONFIRM_PASSWORD'
  )

const date = () =>
  Yup.string()
    .nullable()
    .test('YYYY-MM-DD date', 'FORM_VALIDATION_DATE', (value) => {
      if (typeof value === 'undefined') return true
      const [yyyy, mm, dd] = _.split(value, '-')
      return Number(yyyy) && Number(mm) && Number(dd)
    })

const maxLength = (length) =>
  Yup.string().max(length)

const maxLengthNoWhitespace = (length) =>
  Yup.string()
    .trim()
    .max(length, i18next.t('FORM_VALIDATION_MAX_LENGTH', { max: length }))
    .test(
      'no-whitespace',
      'FORM_VALIDATION_NO_WHITESPACE_ONLY',
      (value) => value && value.trim().length > 0
    )
    .required('FORM_VALIDATION_REQUIRED')

const telephoneE164 = () => optionalString().matches(/^\+[1-9]\d{1,14}$/, 'FORM_VALIDATION_PHONE_E_164')

const simpleTelephoneValidation = () => Yup.string().matches(/^\d{1,15}$/, 'FORM_VALIDATION_PHONE_NUMERIC')

const countryCodeValidation = () =>
  Yup.string()
    .when('telephone', {
      is: (val) => val !== null && val !== undefined,
      then: requiredString()
    })

const validators = {
  object: Yup.object,
  requiredBool,
  requiredString,
  requiredStringWhen,
  requiredProfessionWhen,
  email,
  password,
  optionalPassword,
  confirmPassword,
  optionalConfirmPassword,
  date,
  optionalString,
  maxLength,
  maxLengthNoWhitespace,
  telephoneE164,
  simpleTelephoneValidation,
  countryCodeValidation
}

export default validators
